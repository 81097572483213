<template><section /></template>
    
    <script>
    
    export default {
      name: 'QuestionLoader',
    };
    </script>
    
    <style lang="scss">
      
    </style>