<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <div v-if="isLoading">
      <question-skeleton-loader />
    </div>
    <aom-languages v-else>
      <b-row>
        <b-col md="12">
          <validation-provider
            v-slot="validationContext"
            ref="name"
            :rules="{ required: true }"
            vid="name"
            name="Category Name"
          >
            <b-form-group
              label="Category Name"
              label-for="category-name"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Category Name"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- submit and reset -->
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="onReset()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            <b-spinner
              v-if="isUpdating"
              small
            />
            <span
              v-if="isUpdating"
            > Updating...</span>
            <span v-else>Submit</span>
          </b-button>
        </b-col>
      </b-row>
    </aom-languages>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AomLanguages from "@aom-core/AomLanguages";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getValidationState } from "@/libs/utils";
import QuestionSkeletonLoader from "@/views/components/questions/QuestionSkeletonLoader.vue";
import resourcesService from "@/services/resourcesService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    AomLanguages,
    QuestionSkeletonLoader
  },
  directives: {
    Ripple,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    resourceCategoryId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      name: "",
      isLoading: false,
      isUpdating: false
    };
  },
  watch: {
    resourceCategoryId: {
      handler() {
        if (this.resourceCategoryId) {
          this.fetchResourceCategory();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchResourceCategory();
  },
  methods: {
    async fetchResourceCategory() {
      try {
        this.isLoading = true;
        if (this.resourceCategoryId) {
          const response = await resourcesService.getResourceCategory(this.resourceCategoryId);
          if (response) {
            this.name = response.data.name;
          }
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Resource category not saved."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        const payload = {
          name: this.name
        };
        const response = await resourcesService.updateResourceCategory(
          this.resourceCategoryId,
          payload
        );
        if (response) {
          this.$emit("resourceCategoryUpdated");
          this.$toast(makeSuccessToast("Resource category updated successfully."));
          this.$root.$emit("bv::toggle::collapse", "edit-resource-right");
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Resource category not saved."));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    onReset() {
      this.name = "";
      this.$root.$emit("bv::toggle::collapse", "edit-resource-right");
    }
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script> 

<style>

</style>