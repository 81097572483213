import { render, staticRenderFns } from "./EditResourceCategory.vue?vue&type=template&id=240b3262&"
import script from "./EditResourceCategory.vue?vue&type=script&lang=js&"
export * from "./EditResourceCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports