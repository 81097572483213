<template>
  <b-card>
    <!-- Table Top -->
    <b-row>
      <b-col
        cols="30"
        md="12"
      >
        <div class="d-flex justify-content-end">
          <b-button
            v-b-toggle.add-resource-category-right
            variant="primary"
            style="margin-left:10px;"
          >
            <span class="text-nowrap">Add Resource Category</span>
          </b-button>

          <b-sidebar
            id="add-resource-category-right"
            title="Add Resource Category"
            bg-variant="white"
            right
            backdrop
            shadow
            style="min-width: 600px !important;"
            width="600px"
          >
            <div>
              <add-resource-category
                @resourceCategoryAdded="onRefreshItems"
              />
            </div>
          </b-sidebar>
        </div>
      </b-col>
    </b-row>

    <resource-categories-list
      ref="resourceCategoriesList"
      @edit="editResourceCategory"
    />

    <b-sidebar
      id="edit-resource-right"
      title="Edit Resource Category"
      bg-variant="white"
      right
      backdrop
      shadow
      style="min-width: 600px !important;"
      width="600px"
    >
      <div>
        <edit-resource-category
          :resource-category-id="resourceCategoryId"
          @resourceCategoryUpdated="onRefreshItems"
        />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BSidebar,
  VBToggle
} from 'bootstrap-vue';
import Ripple from "vue-ripple-directive";
import AddResourceCategory from "../../../components/forms/resources/AddResourceCategory.vue";
import ResourceCategoriesList from './ResourceCategoriesList.vue';
import EditResourceCategory from "../../../components/forms/resources/EditResourceCategory.vue";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BSidebar,
    AddResourceCategory,
    ResourceCategoriesList,
    EditResourceCategory
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      modalShow: false,
      element : { },
      dir: false,
      rows: [],
      searchTerm: '',
      resourceCategoryId: null,
    };
  },
  methods: {
    hiddenModal(isShow) {
      this.modalShow = isShow;
    },
    onRefreshItems() {
      this.$refs.resourceCategoriesList.loadItems();
    },
    editResourceCategory(id) {
      this.resourceCategoryId = id;
    },
  }
};
</script>
<style>
  .vgt-input  {
    max-width: 16em !important;
    }
</style>
